<template>
  <div class="networkBase">
    <v-row>
      <v-col cols="8">
        <network :nodes="statesAsNodes" :edges="routesAsEdges" :options="options" :events="['select']" @select="onSelect"/>
      </v-col>
      <v-col cols="4" class="my-2">
        <v-card v-if="selectedNode.id">
          <v-card-title>{{ selectedNode.name }}</v-card-title>
          <v-divider />
          <properties-list show-empty="false" :headers="nodeHeaders" :data="selectedNode"></properties-list>
        </v-card>
        <v-card v-else-if="selectedEdge.id">
          <v-card-title>{{ selectedEdge.name }}</v-card-title>
          <v-divider />
          <properties-list show-empty="false" :headers="edgeHeaders" :data="selectedEdge"></properties-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Network } from 'vue-visjs'
import PropertiesList from "./PropertiesList";

export default {
  name: "StatesAndRoutes",
  props: {
    states: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'Network': Network,
    'PropertiesList': PropertiesList,
  },
  data: function () {
    return {
      selectedNode: {},
      nodeHeaders: [
        {name: 'ID:', value: 'id'},
        {name: 'CFT код:', value: 'cft'},
        // {name: 'CanSigned:', value: 'canSigned'},
        {name: 'Активный:', value: 'isActive'},
        {name: 'Архивный:', value: 'isArchive'},
        {name: 'Выход из архива:', value: 'outArchive'},
      ],
      selectedEdge: {},
      edgeHeaders: [
        {name: 'ID:', value: 'id'},
        {name: 'Старое состояние:', value: 'oldState'},
        {name: 'Новое состояние:', value: 'newState'},
        {name: 'Скрывать коммент:', value: 'hideComment'},
        {name: 'Активный:', value: 'isActive'},
        {name: 'Отключен:', value: 'isDisabled'},
        {name: 'Цвет:', value: 'routeColor'},
      ],
      options: {
        nodes: {
          shape: 'box',
          shadow: true,
          // size: 10,
          color: { background: "#ffffff" },
        },
        physics: {
          enabled: false,
        },
        interaction: {
          selectConnectedEdges: false,
          zoomView: false,
        },
        edges: {
          width: 0.7,
          arrows: {
            to: {
              enabled: true,
              scaleFactor: 0.7,
            }
          },
        },
        // clickToUse: true,
        height: '500px',
        locale: 'ru',
      }
    }
  },
  computed: {
    statesAsNodes() {
      return this.states.states.map((e) => {
        return {
          id: e.id,
          label: e.name,
        }
      })
    },
    routesAsEdges() {
      return this.states.routes.map((e) => {
        return {
          id: e.id,
          from: e.oldState,
          to: e.newState,
        }
      })
    }
  },
  methods: {
    onSelect(event) {
      this.selectedEdge = {}
      this.selectedNode = {}
      if (event.nodes.length === 1) {
        this.selectedNode = this.states.states.find((e) => e.id === event.nodes[0])
      } else {
        this.selectedEdge = this.states.routes.find((e) => e.id === event.edges[0])
      }
    }
  }
}
</script>

<style scoped>
.networkBase {
  min-height: 500px;
}
</style>
<template>
  <div>
    <v-data-table
        :headers="attributesTableHeaders"
        :items="newAttrsTree"
        item-key="id"
        sort-by="order"
        disable-pagination
        hide-default-header
        hide-default-footer
        group-by="parentId"
        class="elevation-1"
        dense
        single-expand
        show-expand
        :search="search">
      <template v-slot:group.header="{items, isOpen, toggle}">
        <th colspan="5" v-if="items && items[0].parentId > 0" @click="toggle">
          <v-icon small>
            {{ attributeMoreInfo(items[0].parentId).type === 'L' ? 'mdi-folder' : 'mdi-table' }}
          </v-icon>{{attributeMoreInfo(items[0].parentId).name}}
          <v-icon small @click="showAttributeFull(items[0].parentId)">mdi-help-box</v-icon>
        </th>
        <th colspan="5" v-else-if="items && items[0].parentId < 0" @click="toggle">
         Системные атрибуты
        </th>
        <th colspan="5" v-else @click="toggle">
          Без группировки
        </th>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card elevation="0" class="my-1">
            <properties-list  :headers="attributeFullHeaders" :data="item">
              <template v-slot:referenceName="{header, data}">
                <v-list-item-content>{{ header }}</v-list-item-content>
                <router-link
                    :to="'/reference/'+ data.referenceId"
                    target="_blank"
                    class="justify-end"
                >
                 {{  data.referenceName }}
                </router-link>
              </template>
            </properties-list>
          </v-card>
        </td>
      </template>
      <template v-slot:item.isVisible="{ item }">
        <v-icon small color="black" :disabled="!item.isVisible">mdi-eye</v-icon>
        <v-icon small color="black" :disabled="!item.makeHistory">mdi-history</v-icon>
        <v-icon small color="black" :disabled="item.isReadonly">mdi-pencil</v-icon>
      </template>
    </v-data-table>
    <v-dialog
        v-model="fullAttributeDialog"
        width="500"
    >
      <v-card v-if="fullAttributeDialog">
        <v-card-title class="text-h5 grey lighten-2">
          {{ fullAttribute.name }}
          <v-spacer></v-spacer>
          <v-icon @click="fullAttributeDialog = false">mdi-close</v-icon>
        </v-card-title>
        <properties-list  :headers="attributeFullHeaders" :data="fullAttribute"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PropertiesList from "./PropertiesList";

export default {
  name: "AttributesList",
  props: ['attributes', 'search'],
  data: function () {
    return {
      // search: '',
      fullAttribute: null,
      fullAttributeDialog: false,
      attributeFullHeaders: [
        {name: 'ID:',  value: 'id'},
        {name: 'CFT код:',  value: 'cft'},
        {value: 'remark', name: 'Примечание:'},
        {value: 'typeName', name: 'Тип:'},
        {value: 'referenceName', name: 'Перечисление:'},
        {value: 'guid', name: 'GUID:'},
        {value: 'ddlTable', name: 'Таблица для хранения:'},
        {value: 'evaluation', name: 'Код вычисления:'},
        {value: 'isReadonly', name: 'Только для чтения:'},
        {value: 'isVisible', name: 'Видимый:'},
        {value: 'makeHistory', name: 'Сохранять историю:'},
        {value: 'referenceColId', name: 'Столбец для отображения в справочнике:'},
        {value: 'lookupSqlRule', name: 'SQL код для столбца в запрсое:'},
        {value: 'code', name: 'Алиас атрибута:'},
      ],
      attributesTableHeaders: [
        {value: 'name'},
        { value: 'typeName' },
        { value: 'cft', align: 'end'},
        { value: 'isVisible', align: 'center'},
        { value: 'data-table-expand'},
      ],
    }
  },
  computed: {
    newAttrsTree() {
      return this.attributes
          .filter((e) => e.type !== "L" && e.type !== "T");
    },
    attributesByIdData() {
      return this.attributes
          .reduce((acc, e) => {
            acc[e.id] = e
            return acc
          }, {});
    },
  },
  methods: {
    attributeMoreInfo(id) {
      let val = this.attributesByIdData[id]
      if (val) {
        return val
      }
      return {type: 'empty'}
    },
    showAttributeFull(id) {
      this.fullAttribute = this.attributeMoreInfo(id)
      this.fullAttributeDialog = true
    }
  },
  components: {
    'PropertiesList': PropertiesList,
  }
}
</script>

<style scoped>

</style>
<template>
  <page-wrapper :loading="!typeData.typeName">
    <h1>{{ typeData.typeName }}<span v-if="!typeData.visible"> (Скрыт)</span></h1>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card min-height="100%" elevation="0">
            <v-card-title><h4>Общая информация</h4></v-card-title>
            <v-divider />
            <properties-list :data="typeData" :headers="typeDataHeaders" />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card min-height="100%" elevation="0">
            <v-card-title>
              <h4>Аттрибуты</h4>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  label="Поиск"
                  class="mx-4"
              ></v-text-field>
            </v-card-title>
            <v-divider />
            <attributes-list :attributes="allAttributes" :search="search"/>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card min-height="100%" elevation="0">
            <v-card-title>
              <h4>Статусы</h4>
            </v-card-title>
            <v-divider />
            <states-and-routes :states="statesData"/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </page-wrapper>
</template>


<script>
import PropertiesList from "../components/PropertiesList";
import AttributesList from "../components/AttributesList";
import StatesAndRoutes from "../components/StatesAndRoutes";
import PageWrapper from "../components/PageWrapper";

export default {
  data: function () {
    return {
      search: '',
      typeDataHeaders: [
        {value: 'typeId', name: 'ID:'},
        {name: 'CFT код:', value: 'cft'},
        {value: 'firstState', name: 'Первичное состояние:'},
        {value: 'remark', name: 'Примечание'},
        {value: 'guid', name: 'GUID'},
        {value: 'captionTemplate', name: 'Шаблон заголовка:'},
      ],
    }
  },
  computed: {
    allAttributes() {
      return this.$store.getters.allAttributes;
    },
    typeData() {
      return this.$store.getters.typeData;
    },
    statesData() {
      return this.$store.getters.statesData;
    },
  },
  watch: {
    $route(to) {
      this.fetchData(to)
    },
  },
  methods: {
    docTypeIdToConfigurationUrl(id) {
      return "/documents/" + id + "/configuration"
    },
    fetchData(route) {
      this.$store.commit('clearConfiguration')
      this.$store.dispatch('fetchTypeData', route.params.typeId);
      this.$store.dispatch('fetchAttributes', route.params.typeId);
      this.$store.dispatch('fetchStates', route.params.typeId);
      if (route.query.search) {
        this.search = decodeURIComponent(route.query.search)
      }
    },
  },
  mounted() {
    this.fetchData(this.$route)
  },
  components: {
    'PropertiesList': PropertiesList,
    'AttributesList': AttributesList,
    'StatesAndRoutes': StatesAndRoutes,
    'PageWrapper': PageWrapper,
  }
}
</script>
